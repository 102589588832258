.footer {
    height: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    /* background-color: #141127; */
}

.footer-container {
    /* color: white; */
}
