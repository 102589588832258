.cell-table-custom-class.small-padding {
    padding: 0.4em;
}

.tasklist-box {
    max-height: 100%;
    overflow: scroll;
}
.pagination {
    float: right;
}

.pagination > button.ms-Button.ms-Button--default {
    background-color: transparent;
}

.go-next-button-container {
    text-align: center;
    margin-top: 5em;
}

.cell-table-icon-class {
    font-weight: bold;
    /* font-size:14px; */
}

.cell-table-icon-class.only-icon-list {
    font-size: 1.1em;
}

.approval-status-icon-container {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    height: 100%;
}

.approval-status-icon {
    vertical-align: middle;
    display: inline-block;
    background-color: #fbeb57;
    height: 1em;
    width: 1em;
    border-radius: 1em;
}
.approval-status-icon.approved{
    color: green;
}
.approval-status-icon.rejected{
    color: red;
}
.center-commandbar-checkbox, .center-commandbar-combobox, .center-commandbar-label {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.task-list.modal-profile.list-element, .task-list.snippet-profile.list-element {
    display: inline-block;
}
/* 
div.snippet-profile-container {
    padding: 0em!important;
}
div.snippet-profile-separator-container {
    padding-top: 0em;
    padding-bottom: 0em;
} */

.snippet-box {
    margin-top: 1em;
}

.clip-checkbox {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.show-detail-row-icon {
    height: 1em;
    padding: 0em;
    margin: 0em;
    vertical-align: middle;
    display: inline-block;
}

.dialog-shimmer-default {
    margin: 0.2em;
}

.list-task-container {
    /* MAKE IT SCOLLABLE
    max-height: 30em;
    overflow-y: scroll; */
}

.dialog-body.shimmer {
    margin-top: 2em;
    margin-bottom: 1em;
}

.task-detail-dialog > .ms-Overlay {
    background: transparent;
}

.task-detail-dialog > .ms-Dialog-main {
    min-width: 30em;
    max-width: 80em;
}

.list-task-detail.container {
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    background-color: #fff;
    display: flex;
}


.list-task-detail.clip-title {
    font-weight: bold;
}
.list-task-detail.no-profile-title {
    font-style: italic;
}

.list-element-icon {
    vertical-align: middle;
    margin-left: 0.3em;
    margin-right: 0.3em;
}
.list-element-icon.profiles-info {
    font-size: 1.5em;
}

.task-detail-view {
    position: absolute;
    height: 20em;
    width: 100%;
    background-color: white;
    border: 0.1em solid #ccc;
}

.list-element-part.max-width {
    max-width: 70em;
}

.detail-task-clip-checkbox-container {
    margin-top: 0.3em;
    margin-right: 1em;
}


.list-task-detail-infos > div {
    display: inline-block;
    padding-right: 1em;
}

.accordion-container {
    border: 0.1em solid #f4f4f4;
    min-height: 3em;
}


.accordion-body, .accordion-footer {
    margin: 1em;
}
.accordion-footer.shimmer {
    margin-top: 3em;
}

.accordion-footer {
    text-align: right;
}

.accordion-footer > button {
    margin: 1em;
}

.accordion-body.shimmer {
    margin-top: 2em;
    margin-bottom: 2em;
}

.accordion-shimmer-default {
    margin: 0.5em;
}
