.task-transcription-box-container {
    border: 0.1em solid;
    padding: 0.5em;
    margin: 0.5em;
    min-height: 15em;
    max-height: 25em;
    overflow-x: scroll;
    text-align: justify;
}

.task-transcription-box-container.need-attention {
    border-color: red;
}

.transcription-word {
    display: inline-block;
}

.transcription-word.selected {
    font-weight: bold;
    background-color: #efce09!important;
}

.transcription-word.tag.tag-keyword {
    color: red;
    font-weight: bold;
}

.transcription-word.tag.tag-keyword_a {
    color: green;
    font-weight: bold;
}

.transcription-word.tag.tag-tag {
    color: red;
    vertical-align: sub;
    /* HACK: with icon height pass from 19 to 22. */
    height: 16px;
}

.transcription-word.tag.tag-thumbnail {
    color: blue;
    vertical-align: sub;
    /* HACK: with icon height pass from 19 to 22. */
    height: 16px;
}


.transcription-word.tag.tag-place {
    color: blue;
    font-weight: bold;
}

.transcription-word.clip {
    background-color: #ebf9f7;
}
.transcription-word.clip1 {
    background-color: #90ded3;
}
.transcription-word.clip2 {
    background-color: #45c7b5;
}

.transcription-word.clip.metadata-missing {
    background-color: #fffad5;
}
.transcription-word.clip1.metadata-missing {
    background-color: #fff49a;
}
.transcription-word.clip2.metadata-missing {
    background-color: #fce100;
}



.transcription-word.highlighted {
    background-color: #f8e8f4!important;
}

/* .transcription-word.action-selected {
    background-color: #efce09;
} */

/* .transcription-word.clip:hover ~ .transcription-word.clip {
    background-color: red;
} */

.transcription-word.selected.clip {
    cursor: col-resize;
}

.tooltip-row {
    display: inline-block;
    margin: 0.25em;
}
