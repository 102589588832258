.task-action-button-container {
    font-size: 14px;
    border: 0.1em solid;
    margin: 0.5em;
}

.action-button-element-box {
    padding: 0.25em;
}

.action-button.button {
    width: 100%;
}

.action-button-rapid {
    color: #fce100;
}
