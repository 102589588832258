.task-timeline-container {
    margin: 0.5em;
}

.timeline-box, .clip-timeline-instance-box {
    padding: 0px;
    border: 1px solid #d1cbc6;
    margin: 1em;
}

.timeline, .clip-timeline-instance {
    height: 2.5em;
    padding: 0px;
    position: relative;
}

.timeline-metadata, .timeline-clip {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    height: 2.5em;
    width: 0.2em;
    background-color: transparent;
    display: inline-block;
    position: absolute;
    z-index: 2;
    background-color: black;
    border-radius: 0.25em;
}

.timeline-metadata {
    margin-top: 0em;
}

.timeline-metadata.metadata-tag {
    height: 0.5em;
    width: 0.5em;
    margin-top: 1em !important;
    border-radius: 50%;
}

.tooltip-metadata {
    z-index: 2;
}

#progress-bar, #clip-progress-bar  {
    display: inline-block;
    position: absolute;
    z-index: 2;
    margin-left: 0%;
    padding: 0px;
    width: 0px;
    bottom: -28px;
}

.timeline-clip {
    background-color: rgba(42,173,42,0.3);
    z-index: 1;
    border: 0.15em solid #2aad2a;  /* no border: remove */
    height: 1.9em;
    margin-top: 0.3em;
}

.timeline-clip.metadata-missing {
    background-color: rgba(252,225,0,0.3);
    border: 0.16em solid #fce100;  /* no border: remove */
}

.timeline-metadata[data-type='time'] {
    background-color: #d1cbc6;
    top: -1.6em;
    height: 25%;
    width: 1px;
}
.timeline-metadata[data-type='time'] > span {
    font-size: 60%;
    position: absolute;
    right: -1.75em;
    top: -1.25em;
}

.timeline-metadata.metadata-tag[data-type='tag'] {
    background-color: red;
}

.timeline-metadata.metadata-tag[data-type='thumbnail'] {
    background-color: blue;
}

.timeline-metadata[data-type='keyword'] {
    background-color: red;
    margin-top: 0.3em!important;  /* no border: remove */
    border-radius: 0.15em;
    height: 1.9em;
    width: 0.1em;
}

.timeline-metadata[data-type='keyword_a'] {
    background-color: green;
    margin-top: 0.3em!important;  /* no border: remove */
    height: 1.9em;
    border-radius: 0.15em;
    width: 0.1em;
}

.timeline-metadata[data-type='place'] {
    background-color: blue;
    margin-top: 0.15em;  /* no border: remove */
}

.badge.badge-clip-profiles {
    display: inline-block;
    color: white;
    font-weight: bold;
    font-size: 9px;
    background: #2aad2a;
    margin-left: 0.65em;
    margin-top: 0em;
    padding-top: 0.1em;
    padding-bottom: 0.3em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    border-radius: 15%;
}

.badge.badge-clip-profiles.metadata-missing {
    background: #fce100;
}
