.task-player-container {
    margin: 0.5em;
}

.player-wrapper > div{
  height: inherit!important;
  width: inherit!important;
}

.player-button-container {
    position: absolute;
}

.player-button.speed-button {
    color: white;
    background-color: transparent;
    z-index: 2;
    padding: 0px;
    min-width: 32px;
    text-shadow: 1px 1px 2px black;
}

.player-button.speed-button.selected {
    color: black;
}
