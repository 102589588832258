.fixed-full-size-overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    background-color: white;
    top: 0;
    left: 0;
    z-index: 9999;
}

.shimmer-task-overlay {
 margin-bottom: 1em;
}
