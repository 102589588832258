.profile-list {
    min-height: 10em;
    max-height: 20em;
    overflow-y: scroll;
    border: 1px solid #d1cbc6;
}

.list-element-part.icon {
    margin-bottom: 0.25em;
}

.list-element-part {
    vertical-align: middle;
    padding-left: 0.2em!important;
    padding-right: 0.2em!important;
    display: inline-block;
    max-width: 22em;
}

.server-list {
    min-height: 10em;
    max-height: 15em;
    overflow-y: scroll;
    border-right: 1px solid #d1cbc6;
    border-bottom: 1px solid #d1cbc6;
    border-left: 1px solid #d1cbc6;
}

.modal-label.keyword-clip.title {
    vertical-align: top;
}
.modal-label.keyword-clip-label {
    max-width: 35em;
}

.clip-metadata-view-shared {
    display: flex;
    float: left;
    margin: 0.5em;
    max-width: 50em;
}

.clip-player-container {
    max-width: 30em;
}

.clip-player-wrapper > div{
  height: inherit!important;
  width: inherit!important;
}

.control-container {
    text-align: center;
    margin: 0.5em;
}

.clip-pause-list {
    margin: 1em;
    max-height: 10em;
    overflow-y: scroll;
}

.clip-timeline-instance-box {
    margin-top: 3em;
    margin-bottom: 3em;
    margin-left: 0em;
    margin-right: 0em;
    height: 2em;
}
.clip-timeline-instance {
    margin: 0em;
}

.clip-pause-delete-button {
    width: auto;
}

.timeline-pause {
    position: absolute;
    background-color: rgba(101,98,98,0.3);
    z-index: 1;
    height: 1.7em;
    border: 0.2em solid #656262;  /* no border: remove */
}

/* .tab-index-name-max-length{
    max-width: 15em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
} */
