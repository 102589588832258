.login-backgroud {
    background-color: #f1f3f6;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    flex: 1;
    display: flex;           /* establish flex container */
    flex-direction: column;  /* make main axis vertical */
    justify-content: center; /* center items vertically, in this case */
    align-items: center;     /* center items horizontally, in this case */
}

.login-dialog {
    height: 30em;
    width: 30em;
    text-align: center;     /* will center text in <p>, which is not a flex item */
    background-color: white;
    bottom: 3px solid blue;
}

.login-form {
    text-align: left;
    margin-top: 1em;
}

.login-img-logo {
    /* width: 100%;
    height: 100%; */
    width: 100%;
    height: auto;
}

.override-style.power-by-label {
    font-size: 8px !important;
    display: inline-block;
    float: left;
}
