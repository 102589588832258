.network-indicator {
    display: inline-block;
}

.network-indicator-icon {
    color: black;
}

.network-indicator-icon {
    vertical-align: middle;
}

.state-0 {
    color: #2aad2a;
}
.state-1 {
    color: yellow;
}
.state-2 {
    color: red;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
