.fixed-full-size-overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    background-color: white;
    top: 0;
    left: 0;
    z-index: 9999;
}

.center-horizontal.center-vertical {
    text-align: center;
    margin-top: 5em;
}

.error-icon {
    font-size: 10em;
}
.error-title {
    font-size: 5em;
}

.error-message {
    font-size: 2em;
}
