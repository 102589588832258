.remote-combobox-option.load-more-container {
    text-align: center;
}
.remote-combobox-option.load-more {
    font-weight: bold;
    text-align: center;
}

.cell-table-custom-class.small-padding {
    padding: 0.4em;
}

.tasklist-box {
    max-height: 100%;
    overflow: scroll;
}
.pagination {
    float: right;
}

.pagination > button.ms-Button.ms-Button--default {
    background-color: transparent;
}

.go-next-button-container {
    text-align: center;
    margin-top: 5em;
}

.cell-table-icon-class {
    font-weight: bold;
    /* font-size:14px; */
}

.cell-table-icon-class.only-icon-list {
    font-size: 1.1em;
}

.approval-status-icon-container {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    height: 100%;
}

.approval-status-icon {
    vertical-align: middle;
    display: inline-block;
    background-color: #fbeb57;
    height: 1em;
    width: 1em;
    border-radius: 1em;
}
.approval-status-icon.approved{
    color: green;
}
.approval-status-icon.rejected{
    color: red;
}
.center-commandbar-checkbox, .center-commandbar-combobox, .center-commandbar-label {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center;
}

.task-list.modal-profile.list-element, .task-list.snippet-profile.list-element {
    display: inline-block;
}
/* 
div.snippet-profile-container {
    padding: 0em!important;
}
div.snippet-profile-separator-container {
    padding-top: 0em;
    padding-bottom: 0em;
} */

.snippet-box {
    margin-top: 1em;
}

.clip-checkbox {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center;
}

.show-detail-row-icon {
    height: 1em;
    padding: 0em;
    margin: 0em;
    vertical-align: middle;
    display: inline-block;
}

.dialog-shimmer-default {
    margin: 0.2em;
}

.list-task-container {
    /* MAKE IT SCOLLABLE
    max-height: 30em;
    overflow-y: scroll; */
}

.dialog-body.shimmer {
    margin-top: 2em;
    margin-bottom: 1em;
}

.task-detail-dialog > .ms-Overlay {
    background: transparent;
}

.task-detail-dialog > .ms-Dialog-main {
    min-width: 30em;
    max-width: 80em;
}

.list-task-detail.container {
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    background-color: #fff;
    display: -webkit-flex;
    display: flex;
}


.list-task-detail.clip-title {
    font-weight: bold;
}
.list-task-detail.no-profile-title {
    font-style: italic;
}

.list-element-icon {
    vertical-align: middle;
    margin-left: 0.3em;
    margin-right: 0.3em;
}
.list-element-icon.profiles-info {
    font-size: 1.5em;
}

.task-detail-view {
    position: absolute;
    height: 20em;
    width: 100%;
    background-color: white;
    border: 0.1em solid #ccc;
}

.list-element-part.max-width {
    max-width: 70em;
}

.detail-task-clip-checkbox-container {
    margin-top: 0.3em;
    margin-right: 1em;
}


.list-task-detail-infos > div {
    display: inline-block;
    padding-right: 1em;
}

.accordion-container {
    border: 0.1em solid #f4f4f4;
    min-height: 3em;
}


.accordion-body, .accordion-footer {
    margin: 1em;
}
.accordion-footer.shimmer {
    margin-top: 3em;
}

.accordion-footer {
    text-align: right;
}

.accordion-footer > button {
    margin: 1em;
}

.accordion-body.shimmer {
    margin-top: 2em;
    margin-bottom: 2em;
}

.accordion-shimmer-default {
    margin: 0.5em;
}

.task-player-container {
    margin: 0.5em;
}

.player-wrapper > div{
  height: inherit!important;
  width: inherit!important;
}

.player-button-container {
    position: absolute;
}

.player-button.speed-button {
    color: white;
    background-color: transparent;
    z-index: 2;
    padding: 0px;
    min-width: 32px;
    text-shadow: 1px 1px 2px black;
}

.player-button.speed-button.selected {
    color: black;
}

.task-timeline-container {
    margin: 0.5em;
}

.timeline-box, .clip-timeline-instance-box {
    padding: 0px;
    border: 1px solid #d1cbc6;
    margin: 1em;
}

.timeline, .clip-timeline-instance {
    height: 2.5em;
    padding: 0px;
    position: relative;
}

.timeline-metadata, .timeline-clip {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    height: 2.5em;
    width: 0.2em;
    background-color: transparent;
    display: inline-block;
    position: absolute;
    z-index: 2;
    background-color: black;
    border-radius: 0.25em;
}

.timeline-metadata {
    margin-top: 0em;
}

.timeline-metadata.metadata-tag {
    height: 0.5em;
    width: 0.5em;
    margin-top: 1em !important;
    border-radius: 50%;
}

.tooltip-metadata {
    z-index: 2;
}

#progress-bar, #clip-progress-bar  {
    display: inline-block;
    position: absolute;
    z-index: 2;
    margin-left: 0%;
    padding: 0px;
    width: 0px;
    bottom: -28px;
}

.timeline-clip {
    background-color: rgba(42,173,42,0.3);
    z-index: 1;
    border: 0.15em solid #2aad2a;  /* no border: remove */
    height: 1.9em;
    margin-top: 0.3em;
}

.timeline-clip.metadata-missing {
    background-color: rgba(252,225,0,0.3);
    border: 0.16em solid #fce100;  /* no border: remove */
}

.timeline-metadata[data-type='time'] {
    background-color: #d1cbc6;
    top: -1.6em;
    height: 25%;
    width: 1px;
}
.timeline-metadata[data-type='time'] > span {
    font-size: 60%;
    position: absolute;
    right: -1.75em;
    top: -1.25em;
}

.timeline-metadata.metadata-tag[data-type='tag'] {
    background-color: red;
}

.timeline-metadata.metadata-tag[data-type='thumbnail'] {
    background-color: blue;
}

.timeline-metadata[data-type='keyword'] {
    background-color: red;
    margin-top: 0.3em!important;  /* no border: remove */
    border-radius: 0.15em;
    height: 1.9em;
    width: 0.1em;
}

.timeline-metadata[data-type='keyword_a'] {
    background-color: green;
    margin-top: 0.3em!important;  /* no border: remove */
    height: 1.9em;
    border-radius: 0.15em;
    width: 0.1em;
}

.timeline-metadata[data-type='place'] {
    background-color: blue;
    margin-top: 0.15em;  /* no border: remove */
}

.badge.badge-clip-profiles {
    display: inline-block;
    color: white;
    font-weight: bold;
    font-size: 9px;
    background: #2aad2a;
    margin-left: 0.65em;
    margin-top: 0em;
    padding-top: 0.1em;
    padding-bottom: 0.3em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    border-radius: 15%;
}

.badge.badge-clip-profiles.metadata-missing {
    background: #fce100;
}

.fixed-full-size-overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    background-color: white;
    top: 0;
    left: 0;
    z-index: 9999;
}

.center-horizontal.center-vertical {
    text-align: center;
    margin-top: 5em;
}

.error-icon {
    font-size: 10em;
}
.error-title {
    font-size: 5em;
}

.error-message {
    font-size: 2em;
}

.contextMenu {
    position: fixed;
    background: white;
    box-shadow: 0px 2px 10px #999999;
    z-index: 999999;
    max-height: 300px;
    overflow: scroll;
    font-size: 1.2em;
}

.contextMenu--option {
    text-decoration: none;
    color: #333;
    border: 1px solid transparent;
    cursor: pointer;
    display: block;
    height: 30px;
    line-height: 30px;
    padding: 0 18px;
    position: relative;
}

.contextMenu--option__disabled {
    color: #999999;
    pointer-events: none;
}

.contextMenu--separator {
    width: 100%;
    height: 1px;
    background: #CCCCCC;
    margin: 0 0 0 0;
}

.contextMenu--option:hover{
    background-color: #eaeaea;
    color: #000;
}

.fixed-full-size-overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    background-color: white;
    top: 0;
    left: 0;
    z-index: 9999;
}

.shimmer-task-overlay {
 margin-bottom: 1em;
}

.task-action-button-container {
    font-size: 14px;
    border: 0.1em solid;
    margin: 0.5em;
}

.action-button-element-box {
    padding: 0.25em;
}

.action-button.button {
    width: 100%;
}

.action-button-rapid {
    color: #fce100;
}

.task-transcription-box-container {
    border: 0.1em solid;
    padding: 0.5em;
    margin: 0.5em;
    min-height: 15em;
    max-height: 25em;
    overflow-x: scroll;
    text-align: justify;
}

.task-transcription-box-container.need-attention {
    border-color: red;
}

.transcription-word {
    display: inline-block;
}

.transcription-word.selected {
    font-weight: bold;
    background-color: #efce09!important;
}

.transcription-word.tag.tag-keyword {
    color: red;
    font-weight: bold;
}

.transcription-word.tag.tag-keyword_a {
    color: green;
    font-weight: bold;
}

.transcription-word.tag.tag-tag {
    color: red;
    vertical-align: sub;
    /* HACK: with icon height pass from 19 to 22. */
    height: 16px;
}

.transcription-word.tag.tag-thumbnail {
    color: blue;
    vertical-align: sub;
    /* HACK: with icon height pass from 19 to 22. */
    height: 16px;
}


.transcription-word.tag.tag-place {
    color: blue;
    font-weight: bold;
}

.transcription-word.clip {
    background-color: #ebf9f7;
}
.transcription-word.clip1 {
    background-color: #90ded3;
}
.transcription-word.clip2 {
    background-color: #45c7b5;
}

.transcription-word.clip.metadata-missing {
    background-color: #fffad5;
}
.transcription-word.clip1.metadata-missing {
    background-color: #fff49a;
}
.transcription-word.clip2.metadata-missing {
    background-color: #fce100;
}



.transcription-word.highlighted {
    background-color: #f8e8f4!important;
}

/* .transcription-word.action-selected {
    background-color: #efce09;
} */

/* .transcription-word.clip:hover ~ .transcription-word.clip {
    background-color: red;
} */

.transcription-word.selected.clip {
    cursor: col-resize;
}

.tooltip-row {
    display: inline-block;
    margin: 0.25em;
}

.profile-list {
    min-height: 10em;
    max-height: 20em;
    overflow-y: scroll;
    border: 1px solid #d1cbc6;
}

.list-element-part.icon {
    margin-bottom: 0.25em;
}

.list-element-part {
    vertical-align: middle;
    padding-left: 0.2em!important;
    padding-right: 0.2em!important;
    display: inline-block;
    max-width: 22em;
}

.server-list {
    min-height: 10em;
    max-height: 15em;
    overflow-y: scroll;
    border-right: 1px solid #d1cbc6;
    border-bottom: 1px solid #d1cbc6;
    border-left: 1px solid #d1cbc6;
}

.modal-label.keyword-clip.title {
    vertical-align: top;
}
.modal-label.keyword-clip-label {
    max-width: 35em;
}

.clip-metadata-view-shared {
    display: -webkit-flex;
    display: flex;
    float: left;
    margin: 0.5em;
    max-width: 50em;
}

.clip-player-container {
    max-width: 30em;
}

.clip-player-wrapper > div{
  height: inherit!important;
  width: inherit!important;
}

.control-container {
    text-align: center;
    margin: 0.5em;
}

.clip-pause-list {
    margin: 1em;
    max-height: 10em;
    overflow-y: scroll;
}

.clip-timeline-instance-box {
    margin-top: 3em;
    margin-bottom: 3em;
    margin-left: 0em;
    margin-right: 0em;
    height: 2em;
}
.clip-timeline-instance {
    margin: 0em;
}

.clip-pause-delete-button {
    width: auto;
}

.timeline-pause {
    position: absolute;
    background-color: rgba(101,98,98,0.3);
    z-index: 1;
    height: 1.7em;
    border: 0.2em solid #656262;  /* no border: remove */
}

/* .tab-index-name-max-length{
    max-width: 15em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
} */

.text-bold {
    font-weight: bold;
}
.task-container {
    background-color: white;
}

.modal-label.title {
    font-weight: bold;
}

.ms-Label.modal-label {
    display: inline-block;
}

.profile-label-container {
    margin-bottom: 1em;
}

.modal-profile.list-element > *, .snippet-profile.list-element > * {
    display: inline-block;
    /* padding: 0.1em; */
}
.task-list.modal-profile.list-element {
    display: block;
}

.create-profile-input {
    width: 70%;
    display: inline-block;
}
.create-profile-button {
    margin-right: 0px;
    width: 30%;
}

.add-profile-button {
    margin-right: 1.5em;
    margin-left: 1.5em;
}

.profile-edit-description, .profile-edit-description-paste-button {
    display: inline-block;
}

.profile-edit-description {
    width: 90%;
}

.ms-Button.profile-edit-description-paste-button {
    width: 10%;
    vertical-align: baseline;
 }

.body, .container {
    /* background: #efefef; */
    padding: 0em; /* set 0.5 to have a little marign in content part*/
    min-height: 100%;
    width: 100%;
    height: 100%; /* NEW */
    -webkit-flex: 1 1;
            flex: 1 1;
}

.body {
    height: calc(100vh - 7em)!important;
}

.body .selection {
    overflow-x: auto;
    height: 100%;
}

.body .selection .selection-item {
    display: -webkit-flex;
    display: flex;
    /* padding: 0.5em; */
}

.body .selection .selection-item .name {
    /* margin-left: 1em; */
}

.content {
    -webkit-flex: 1 1;
            flex: 1 1;
    height: 100%;
    overflow-x: scroll;
}

.network-indicator {
    display: inline-block;
}

.network-indicator-icon {
    color: black;
}

.network-indicator-icon {
    vertical-align: middle;
}

.state-0 {
    color: #2aad2a;
}
.state-1 {
    color: yellow;
}
.state-2 {
    color: red;
}

@-webkit-keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}


.NavBar {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    /* padding: 0em 0.5em ; */
    color: white;
    /* background-color: #141127; */
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.NavBar > .navbar-container {
    width: 100%;
    padding-left: 1em;
    padding-right: 1em;
}

.NavBar .searchbox {
    width: 20em;
}

.NavBar .searchbox .ms-SearchBox {
    margin-bottom: 0;
    background-color: white;
}

.navbar-other-information {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    margin-bottom: 0.1em;
}

.menu-icon {
    color: white !important;
}

.title-bar.icon {
    /* vertical-align: sub; */
}

.title-bar.icon.time-separator {
    margin-top: 0.3em;
    font-size: 16px;
}

.title-bar.date.start, .title-bar.date.end {
    margin-left: 0.5em;
    margin-right: 0.5em;
    font-size: 16px;
    text-align: center;
    vertical-align: center;
}

.sidebar {
    border-right: 1px solid;
}

.footer {
    height: 3em;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    color: white;
    /* background-color: #141127; */
}

.footer-container {
    /* color: white; */
}

.login-backgroud {
    background-color: #f1f3f6;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;           /* establish flex container */
    -webkit-flex-direction: column;
            flex-direction: column;  /* make main axis vertical */
    -webkit-justify-content: center;
            justify-content: center; /* center items vertically, in this case */
    -webkit-align-items: center;
            align-items: center;     /* center items horizontally, in this case */
}

.login-dialog {
    height: 30em;
    width: 30em;
    text-align: center;     /* will center text in <p>, which is not a flex item */
    background-color: white;
    bottom: 3px solid blue;
}

.login-form {
    text-align: left;
    margin-top: 1em;
}

.login-img-logo {
    /* width: 100%;
    height: 100%; */
    width: 100%;
    height: auto;
}

.override-style.power-by-label {
    font-size: 8px !important;
    display: inline-block;
    float: left;
}

/*
Palette
#d1cbc6  => Grayish orange.
#666666  => Very dark gray.
#127167  => Very Dark Cyan.
*/

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #333;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* NOTE: you can use ms-fontColor-teal ms-bgColor-teal */
/* DEPRECATED */
.color-primary {
    color: #127167;
}
/* DEPRECATED */
.bg-primary {
    background-color: #127167;
    border-color: #127167;
}
/* DEPRECATED */
.border-primary {
    border-color: #127167;
}
/* DEPRECATED */
.color-secondary {
    color: #d1cbc6;
}
/* DEPRECATED */
.bg-secondary {
    background-color: #d1cbc6;
    border-color: #d1cbc6;
}
/* DEPRECATED */
.border-secondary {
    border-color: #d1cbc6;
}

.color-gray {
    color: #666666;
}
.bg-gray {
    background-color: #666666;
    border-color: #666666;
}
.border-gray {
    border-color: #666666;
}


.color-danger {
    color: #a80000 !important;
}
.color-danger:hover {
    color: #a80000;
}
.bg-danger {
    background-color: #a80000;
    border-color: #a80000;
}
.border-danger {
    border-color: #a80000 !important;
}
.color-danger.border-danger {
    background-color: white;
}
.color-danger.border-danger:hover {
    background-color: #ffe3e3;
}


.color-warning {
    color: #fce100;
}
.bg-warning {
    background-color: #fce100;
    border-color: #fce100;
}
.border-warning {
    border-color: #fce100;
}
.color-warning2 {
    color: #ffaa44;
}
.bg-warning2 {
    background-color: #ffaa44;
    border-color: #ffaa44;
}
.border-warning2 {
    border-color: #ffaa44;
}


.color-success {
    color: #107c10 !important;
}
.color-success.is-disabled {
    color: #ccc !important;
}
.bg-success {
    background-color: #107c10;
    border-color: #107c10;
}
.border-success {
    border-color: #107c10 !important;
}
.border-success.is-disabled {
    border-color: #ccc !important;
}

.color-success.border-success {
    background-color: white;
}
.color-success.border-success:hover {
    background-color: #dffbdf;
}


/*
Set the main wrapper component to
take up the entire height of the page
*/
.App {
    min-height: 100vh;
}
/*
To use Flexbox we need to set the display
setting of the container component to flex.
The we set the direction of the wrapped
components. By default flexbox will order
them by row, but we can also tell it to do
it by column.
*/
.App {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}
/*
Something similar is done to the body
element, except we order the wrapped
component as rows.
*/
.App .body {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex: 1 1;
            flex: 1 1;
    /* 8em => 4em navbar + 4em footer */
    /* height: calc(100vh - 8em); */
}
/*
Flexbox let's us reorder the way the
elements are placed inside the wrapper
component. Using the order rule, we move
the sidebar to the left of the content
component. We also set its width to 12em
and set its flex propertires so it doesn't
grow or shrink with the rest of the page.
*/
.body .sidebar {
    -webkit-order: -1;
            order: -1;
    max-width: 12em;
    -webkit-flex-grow: 0;
            flex-grow: 0;    /* Defined the ability to groe */
    -webkit-flex-shrink: 0;
            flex-shrink: 0;  /* Defines the ability to shrink */
    -webkit-flex-basis: 12em;
            flex-basis: 12em;/* Default value of the element before distribuing the remaing space */
}
/*
Lastly we give some custom height to our
header and footer components.
*/
.App .header {
    height: 4em;
}

/* UTILITY */

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
    text-justify: inter-word;
}

.no-padding {
    padding: 0px;
}

.hidden {
    display: none !important;
}

.fullscreen-toggler {
    position: absolute;
    height: 32px;
    width: 32px;
    bottom: 10px;
    left: 10px;
    z-index: 999;
    opacity: 0.5;
}

.back-to-window {
    font-size: 10px;
    height: 100%;
    width: 100%;
    color: white;
}

.fullscreen-toggler:hover {
    opacity: 1;
}

.fullscreen-toggler:hover > .back-to-window {
    color: white;
}


.blink_me {
  -webkit-animation: blinker 1s linear infinite;
          animation: blinker 1s linear infinite;
}

button.sentry-bug-report-icon {
    position: fixed;
    bottom: 1em;
    right: 1em;
    height: 3em;
    width: 3em;
    border: 0.14em solid #ffd500;
    background-color: #ffd500;
    bottom: 1em;
    right: 1em;
    border-radius: 50%;
    outline: 0em;
    box-shadow: rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
    color: #555;
    animation-name: shake;
    -webkit-animation-name: shake;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    -webkit-animation-duration: 5s;
            animation-duration: 5s;
    transition:
        background-color 100ms linear 0s, color 100ms linear 0s,
        border-color 100ms linear 0s, color 100ms linear 0s,
        opacity 300ms cubic-bezier(0.2, 0, 0, 1) 0s,
        -webkit-transform 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
    transition:
        background-color 100ms linear 0s, color 100ms linear 0s,
        border-color 100ms linear 0s, color 100ms linear 0s,
        opacity 300ms cubic-bezier(0.2, 0, 0, 1) 0s,
        transform 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
    transition:
        background-color 100ms linear 0s, color 100ms linear 0s,
        border-color 100ms linear 0s, color 100ms linear 0s,
        opacity 300ms cubic-bezier(0.2, 0, 0, 1) 0s,
        transform 300ms cubic-bezier(0.2, 0, 0, 1) 0s, -webkit-transform 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
}

button.sentry-bug-report-icon:hover {
    background-color: #facf00;
    border-color: #facf00;
    -webkit-animation-name: shakeAnim;
            animation-name: shakeAnim;
}
/*
button.sentry-bug-report-icon > div > i {
    color: red;
    animation-name: shake;
} */

@-webkit-keyframes shakeAnim {
  0% {right: 1em 0}
  1% {right: 0.8em}
  2% {right: 1.25em}
  3% {right: 0.75em}
  4% {right: 1.50em}
  5% {right: 0.75em}
  6% {right: 1.1em}
  7% {right: 1em 0}
}

@keyframes shakeAnim {
  0% {right: 1em 0}
  1% {right: 0.8em}
  2% {right: 1.25em}
  3% {right: 0.75em}
  4% {right: 1.50em}
  5% {right: 0.75em}
  6% {right: 1.1em}
  7% {right: 1em 0}
}

@-webkit-keyframes shake {
    0% {right: 1em 0}
    1% {right: 0.8em}
    2% {right: 1.25em}
    3% {right: 0.75em}
    4% {right: 1.50em}
    5% {right: 0.75em}
    6% {right: 1.1em}
    7% {right: 1em 0}
}

@keyframes shake {
    0% {right: 1em 0}
    1% {right: 0.8em}
    2% {right: 1.25em}
    3% {right: 0.75em}
    4% {right: 1.50em}
    5% {right: 0.75em}
    6% {right: 1.1em}
    7% {right: 1em 0}
}

