.text-bold {
    font-weight: bold;
}
.task-container {
    background-color: white;
}

.modal-label.title {
    font-weight: bold;
}

.ms-Label.modal-label {
    display: inline-block;
}

.profile-label-container {
    margin-bottom: 1em;
}

.modal-profile.list-element > *, .snippet-profile.list-element > * {
    display: inline-block;
    /* padding: 0.1em; */
}
.task-list.modal-profile.list-element {
    display: block;
}

.create-profile-input {
    width: 70%;
    display: inline-block;
}
.create-profile-button {
    margin-right: 0px;
    width: 30%;
}

.add-profile-button {
    margin-right: 1.5em;
    margin-left: 1.5em;
}

.profile-edit-description, .profile-edit-description-paste-button {
    display: inline-block;
}

.profile-edit-description {
    width: 90%;
}

.ms-Button.profile-edit-description-paste-button {
    width: 10%;
    vertical-align: baseline;
 }
