.contextMenu {
    position: fixed;
    background: white;
    box-shadow: 0px 2px 10px #999999;
    z-index: 999999;
    max-height: 300px;
    overflow: scroll;
    font-size: 1.2em;
}

.contextMenu--option {
    text-decoration: none;
    color: #333;
    border: 1px solid transparent;
    cursor: pointer;
    display: block;
    height: 30px;
    line-height: 30px;
    padding: 0 18px;
    position: relative;
}

.contextMenu--option__disabled {
    color: #999999;
    pointer-events: none;
}

.contextMenu--separator {
    width: 100%;
    height: 1px;
    background: #CCCCCC;
    margin: 0 0 0 0;
}

.contextMenu--option:hover{
    background-color: #eaeaea;
    color: #000;
}
