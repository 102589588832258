
.NavBar {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    /* padding: 0em 0.5em ; */
    color: white;
    /* background-color: #141127; */
    justify-content: space-between;
}

.NavBar > .navbar-container {
    width: 100%;
    padding-left: 1em;
    padding-right: 1em;
}

.NavBar .searchbox {
    width: 20em;
}

.NavBar .searchbox .ms-SearchBox {
    margin-bottom: 0;
    background-color: white;
}

.navbar-other-information {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    margin-bottom: 0.1em;
}

.menu-icon {
    color: white !important;
}

.title-bar.icon {
    /* vertical-align: sub; */
}

.title-bar.icon.time-separator {
    margin-top: 0.3em;
    font-size: 16px;
}

.title-bar.date.start, .title-bar.date.end {
    margin-left: 0.5em;
    margin-right: 0.5em;
    font-size: 16px;
    text-align: center;
    vertical-align: center;
}
