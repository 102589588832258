/*
Palette
#d1cbc6  => Grayish orange.
#666666  => Very dark gray.
#127167  => Very Dark Cyan.
*/

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #333;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* NOTE: you can use ms-fontColor-teal ms-bgColor-teal */
/* DEPRECATED */
.color-primary {
    color: #127167;
}
/* DEPRECATED */
.bg-primary {
    background-color: #127167;
    border-color: #127167;
}
/* DEPRECATED */
.border-primary {
    border-color: #127167;
}
/* DEPRECATED */
.color-secondary {
    color: #d1cbc6;
}
/* DEPRECATED */
.bg-secondary {
    background-color: #d1cbc6;
    border-color: #d1cbc6;
}
/* DEPRECATED */
.border-secondary {
    border-color: #d1cbc6;
}

.color-gray {
    color: #666666;
}
.bg-gray {
    background-color: #666666;
    border-color: #666666;
}
.border-gray {
    border-color: #666666;
}


.color-danger {
    color: #a80000 !important;
}
.color-danger:hover {
    color: #a80000;
}
.bg-danger {
    background-color: #a80000;
    border-color: #a80000;
}
.border-danger {
    border-color: #a80000 !important;
}
.color-danger.border-danger {
    background-color: white;
}
.color-danger.border-danger:hover {
    background-color: #ffe3e3;
}


.color-warning {
    color: #fce100;
}
.bg-warning {
    background-color: #fce100;
    border-color: #fce100;
}
.border-warning {
    border-color: #fce100;
}
.color-warning2 {
    color: #ffaa44;
}
.bg-warning2 {
    background-color: #ffaa44;
    border-color: #ffaa44;
}
.border-warning2 {
    border-color: #ffaa44;
}


.color-success {
    color: #107c10 !important;
}
.color-success.is-disabled {
    color: #ccc !important;
}
.bg-success {
    background-color: #107c10;
    border-color: #107c10;
}
.border-success {
    border-color: #107c10 !important;
}
.border-success.is-disabled {
    border-color: #ccc !important;
}

.color-success.border-success {
    background-color: white;
}
.color-success.border-success:hover {
    background-color: #dffbdf;
}


/*
Set the main wrapper component to
take up the entire height of the page
*/
.App {
    min-height: 100vh;
}
/*
To use Flexbox we need to set the display
setting of the container component to flex.
The we set the direction of the wrapped
components. By default flexbox will order
them by row, but we can also tell it to do
it by column.
*/
.App {
    display: flex;
    flex-direction: column;
}
/*
Something similar is done to the body
element, except we order the wrapped
component as rows.
*/
.App .body {
    display: flex;
    flex-direction: row;
    flex: 1;
    /* 8em => 4em navbar + 4em footer */
    /* height: calc(100vh - 8em); */
}
/*
Flexbox let's us reorder the way the
elements are placed inside the wrapper
component. Using the order rule, we move
the sidebar to the left of the content
component. We also set its width to 12em
and set its flex propertires so it doesn't
grow or shrink with the rest of the page.
*/
.body .sidebar {
    order: -1;
    max-width: 12em;
    flex-grow: 0;    /* Defined the ability to groe */
    flex-shrink: 0;  /* Defines the ability to shrink */
    flex-basis: 12em;/* Default value of the element before distribuing the remaing space */
}
/*
Lastly we give some custom height to our
header and footer components.
*/
.App .header {
    height: 4em;
}

/* UTILITY */

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
    text-justify: inter-word;
}

.no-padding {
    padding: 0px;
}

.hidden {
    display: none !important;
}

.fullscreen-toggler {
    position: absolute;
    height: 32px;
    width: 32px;
    bottom: 10px;
    left: 10px;
    z-index: 999;
    opacity: 0.5;
}

.back-to-window {
    font-size: 10px;
    height: 100%;
    width: 100%;
    color: white;
}

.fullscreen-toggler:hover {
    opacity: 1;
}

.fullscreen-toggler:hover > .back-to-window {
    color: white;
}


.blink_me {
  animation: blinker 1s linear infinite;
}

button.sentry-bug-report-icon {
    position: fixed;
    bottom: 1em;
    right: 1em;
    height: 3em;
    width: 3em;
    border: 0.14em solid #ffd500;
    background-color: #ffd500;
    bottom: 1em;
    right: 1em;
    border-radius: 50%;
    outline: 0em;
    box-shadow: rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
    color: #555;
    animation-name: shake;
    -webkit-animation-name: shake;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in;
    animation-duration: 5s;
    transition:
        background-color 100ms linear 0s, color 100ms linear 0s,
        border-color 100ms linear 0s, color 100ms linear 0s,
        opacity 300ms cubic-bezier(0.2, 0, 0, 1) 0s,
        transform 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
}

button.sentry-bug-report-icon:hover {
    background-color: #facf00;
    border-color: #facf00;
    animation-name: shakeAnim;
}
/*
button.sentry-bug-report-icon > div > i {
    color: red;
    animation-name: shake;
} */

@keyframes shakeAnim {
  0% {right: 1em 0}
  1% {right: 0.8em}
  2% {right: 1.25em}
  3% {right: 0.75em}
  4% {right: 1.50em}
  5% {right: 0.75em}
  6% {right: 1.1em}
  7% {right: 1em 0}
}

@keyframes shake {
    0% {right: 1em 0}
    1% {right: 0.8em}
    2% {right: 1.25em}
    3% {right: 0.75em}
    4% {right: 1.50em}
    5% {right: 0.75em}
    6% {right: 1.1em}
    7% {right: 1em 0}
}
