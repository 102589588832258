.body, .container {
    /* background: #efefef; */
    padding: 0em; /* set 0.5 to have a little marign in content part*/
    min-height: 100%;
    width: 100%;
    height: 100%; /* NEW */
    flex: 1;
}

.body {
    height: calc(100vh - 7em)!important;
}

.body .selection {
    overflow-x: auto;
    height: 100%;
}

.body .selection .selection-item {
    display: flex;
    /* padding: 0.5em; */
}

.body .selection .selection-item .name {
    /* margin-left: 1em; */
}

.content {
    flex: 1;
    height: 100%;
    overflow-x: scroll;
}
